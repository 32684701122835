<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">mdi-cellphone</v-icon>
          Users
          <v-spacer></v-spacer>
          <v-text-field
            v-model="appUserPagination.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe o Nome para buscar"
            single-line
            hide-details
            @keydown.enter="buscar()"
            @click:append="buscar()"
          ></v-text-field>
          <v-spacer></v-spacer>
<!--          <v-switch-->
<!--            v-model="appUsers.active"-->
<!--            :label=" `Usuários ${appUsers.active? 'Ativos': 'Inativos'}`"-->
<!--            single-line-->
<!--            hide-details-->
<!--            class="mr-3"-->
<!--            style="width: 250px"-->
<!--            @change="buscar()"-->
<!--          ></v-switch>-->
<!--          -->
        </v-card-title>

        <v-card-text v-if="!appUsers.items">
          <v-row>
            <v-col md="12" sm="12" xs="12">
              <v-alert dense outlined type="warning" >
                Usuários do aplicativo não encontrados
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="appUsers.items"
          :options.sync="options"
          :server-items-length="appUsers.totalItems"
          item-key="id"
          class="elevation-1"
          v-show="appUsers.items"
          :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                          name: 'appUser',
                          params: { id: item.id }
                        }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";

import {APPUSERS_SEARCH} from "@/store/actions/appuser.type";
import {
  APPUSERS_RESET,
  SET_APPUSER_PAGINATION
} from "@/store/mutations/appuser.type";

export default {
  name: "AppUser",

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(APPUSERS_RESET),
      store.dispatch(APPUSERS_SEARCH)
    ]).then(() => {
      next();
    });
  },

  data: () => ({
    headers: [
      {text: "Nome", value: "name"},
      {text: "CPF", value: "cpf"},
      {text: "Celular", value: "cellPhone"},
      {text: "Data de Nascimento", value: "birthdate"},
      {
        text: "Visualizar",
        value: "actions",
        align: "center",
        sortable: false
      }
    ],

    selected: "",
    options: {},

    //rules
    itemsRules: [
      // v => !!v || "Este campo é obrigatório",
      v =>
        (v && v <= 900) || "O valor deste campo não pode exceder mais que 900"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ]
  }),

  methods: {
    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options

      store.commit(SET_APPUSER_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(APPUSERS_SEARCH);
    },
  },
  watch: {
    options: {
      handler() {
        this.buscar()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["checkUsers", "appUsers", "appUserPagination"])
  }
};
</script>
