var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cellphone")]),_vm._v(" Users "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Buscar","placeholder":"Informe o Nome para buscar","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscar()},"click:append":function($event){return _vm.buscar()}},model:{value:(_vm.appUserPagination.search),callback:function ($$v) {_vm.$set(_vm.appUserPagination, "search", $$v)},expression:"appUserPagination.search"}}),_c('v-spacer')],1),(!_vm.appUsers.items)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12","xs":"12"}},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"warning"}},[_vm._v(" Usuários do aplicativo não encontrados ")])],1)],1)],1):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.appUsers.items),expression:"appUsers.items"}],staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.appUsers.items,"options":_vm.options,"server-items-length":_vm.appUsers.totalItems,"item-key":"id","footer-props":{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#3D1159","icon":"","to":{
                          name: 'appUser',
                          params: { id: item.id }
                        }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }